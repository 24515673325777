import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
// import { AiFillEye } from 'react-icons/ai';

import { AppWrap, MotionWrap } from '../../wrapper';
import './About.scss';
import { getAllAbouts } from '../../content/portfolio-data';

const About = () => {
  const [abouts, setAbouts] = useState([]);

  useEffect(() => {
    const data = getAllAbouts();
    setAbouts(data);
  }, []);

  return (
    <>
      <h2 className="head-text">
        My <span>IT Profile</span>
      </h2>

      <div className="app__profiles">
        {abouts.map((about, index) => (
          <motion.div
            whileInView={{ opacity: 1 }}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.5, type: 'tween' }}
            className="app__profile-item"
            key={about.title + index}
          >
            <a href="#projects">
              <motion.div
                whileInView={{ scale: [0, 1] }}
                whileHover={{ scale: [1, 0.9] }}
                transition={{ duration: 0.25 }}
                className="app__flex"
              >
                <img src={about.image} alt={about.title} />
              </motion.div>
            </a>
            <h2 style={{ marginTop: 20 }}> </h2>
            <h2 className="bold-text" style={{ margin: 'auto' }}>
              <span>{about.title}</span>
            </h2>
            <h2 style={{ marginTop: 10 }}> </h2>
            <p className="p-text">{about.description}</p>
          </motion.div>
        ))}
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(About, 'app__about'),
  'profile',
  'app__whitebg',
);
