import { images } from '../constants';

const ABOUT_DATA = [
  {
    id: 'a1',
    title: 'Cloud Architecture',
    description: 'Design and Build Multi-Tier and Serverless Cloud Architecture in AWS, Azure.',
    image: `${images.awsazure}`,
  },
  {
    id: 'a2',
    title: 'Modernize Applications',
    description: 'Microservices, Containers, Serverless, API Management, DevOps',
    image: `${images.modernizeapplications}`,
  },
  {
    id: 'a3',
    title: 'MERN & Next Full Stack',
    description: 'Full Stack Web Design and Development using NextJS and MERN Stack.',
    image: `${images.mernnext}`,
  },
  {
    id: 'a4',
    title: 'Azure Power Platform',
    description: 'Automate Business Processes, Power Apps, Virtual Agents, Power BI, AI Builder',
    image: `${images.powerplatform}`,
  },
];

export function getAllAbouts() {
  return ABOUT_DATA;
}

const WORK_DATA = [
  {
    id: 'w1',
    title: 'Young Sheldon',
    projectLink: 'https://www.imdb.com/title/tt6226232/',
    image: `${images.sheldon}`,
    tags: ['All', 'Entertainment'],
  },
  {
    id: 'w2',
    title: 'Kinect Sports',
    projectLink: 'https://www.imdb.com/title/tt1982739/',
    image: `${images.kinect}`,
    tags: ['All', 'Sports', 'Entertainment'],
  },
  {
    id: 'w3',
    title: 'Badmintion',
    projectLink: 'https://en.wikipedia.org/wiki/Badminton',
    image: `${images.badminton}`,
    tags: ['All', 'Sports'],
  },
  {
    id: 'w4',
    title: 'Biking',
    projectLink: 'https://en.wikipedia.org/wiki/Cycling',
    image: `${images.biking}`,
    tags: ['All', 'Sports', 'Liesure'],
  },
  {
    id: 'w5',
    title: 'Detective Stories',
    projectLink: 'https://en.wikipedia.org/wiki/The_Adventures_of_Tintin',
    image: `${images.detectivestories}`,
    tags: ['All', 'Entertainment'],
  },
  {
    id: 'w6',
    title: 'Photography',
    projectLink: 'https://en.wikipedia.org/wiki/Photograph',
    image: `${images.photography}`,
    tags: ['All', 'Liesure'],
  },
  {
    id: 'w7',
    title: 'Road Trip',
    projectLink: 'https://en.wikipedia.org/wiki/Travel',
    image: `${images.roadtrip}`,
    tags: ['All', 'Liesure'],
  },
  {
    id: 'w8',
    title: 'Burger',
    projectLink: 'https://en.wikipedia.org/?title=Chicken_burger&redirect=no',
    image: `${images.burger}`,
    tags: ['All', 'Foodie'],
  },
  {
    id: 'w9',
    title: 'Mangoes',
    projectLink: 'https://en.wikipedia.org/wiki/Mango',
    image: `${images.mangoes}`,
    tags: ['All', 'Foodie'],
  },
  {
    id: 'w10',
    title: 'Nuts & Dry Fruits',
    projectLink: 'https://en.wikipedia.org/wiki/Nut_(fruit)',
    image: `${images.nuts}`,
    tags: ['All', 'Foodie'],
  },
  {
    id: 'w11',
    title: 'Horse Gram Sause',
    projectLink: 'https://en.wikipedia.org/wiki/Ulava_charu',
    image: `${images.horsegram}`,
    tags: ['All', 'Foodie'],
  },
  {
    id: 'w12',
    title: 'Java Plum',
    projectLink: 'https://en.wikipedia.org/wiki/Syzygium_cumini',
    image: `${images.javaplum}`,
    tags: ['All', 'Foodie'],
  },
];

export function getAllWorks() {
  return WORK_DATA;
}

const SKILL_DATA = [
  {
    id: 's1',
    name: 'Next JS',
    bgColor: '',
    icon: `${images.nextjs}`,
    tags: ['All', 'Full Stack'],
  },
  {
    id: 's2',
    name: 'React',
    bgColor: '',
    icon: `${images.react}`,
    tags: ['All', 'Full Stack'],
  },
  {
    id: 's3',
    name: 'Redux',
    bgColor: '',
    icon: `${images.redux}`,
    tags: ['All', 'Full Stack'],
  },
  {
    id: 's4',
    name: 'Node JS',
    bgColor: '',
    icon: `${images.node}`,
    tags: ['All', 'Full Stack'],
  },
  {
    id: 's5',
    name: 'GraphQL',
    bgColor: '',
    icon: `${images.graphql}`,
    tags: ['All', 'Full Stack'],
  },
  {
    id: 's6',
    name: 'Git',
    bgColor: '',
    icon: `${images.git}`,
    tags: ['All', 'Full Stack'],
  },
  {
    id: 's7',
    name: 'JavaScript',
    bgColor: '',
    icon: `${images.javascript}`,
    tags: ['All', 'Full Stack'],
  },
  {
    id: 's8',
    name: 'HTML',
    bgColor: '',
    icon: `${images.html}`,
    tags: ['All', 'Full Stack'],
  },
  {
    id: 's9',
    name: 'CSS',
    bgColor: '',
    icon: `${images.css}`,
    tags: ['All', 'Full Stack'],
  },
  {
    id: 's10',
    name: 'AWS/Azure',
    bgColor: '',
    icon: `${images.cloud}`,
    tags: ['All', 'Cloud', 'Modernization'],
  },
  {
    id: 's11',
    name: 'Microservices',
    bgColor: '',
    icon: `${images.microservices}`,
    tags: ['All', 'Modernization'],
  },
  {
    id: 's12',
    name: 'RESTful APIs',
    bgColor: '',
    icon: `${images.api}`,
    tags: ['All', 'Modernization', 'Full Stack'],
  },
  {
    id: 's13',
    name: 'Containers',
    bgColor: '',
    icon: `${images.docker}`,
    tags: ['All', 'Modernization'],
  },
  {
    id: 's14',
    name: 'Kubernetes',
    bgColor: '',
    icon: `${images.k8s}`,
    tags: ['All', 'Modernization'],
  },
  {
    id: 's15',
    name: 'DevOps',
    bgColor: '',
    icon: `${images.devops}`,
    tags: ['All', 'Modernization'],
  },
  {
    id: 's16',
    name: 'Serverless',
    bgColor: '',
    icon: `${images.serverless}`,
    tags: ['All', 'Cloud', 'Modernization'],
  },
  {
    id: 's17',
    name: 'Infra as Code',
    bgColor: '',
    icon: `${images.terraformlogo}`,
    tags: ['All', 'Cloud', 'Modernization'],
  },
  {
    id: 's18',
    name: 'Power Platform',
    bgColor: '',
    icon: `${images.powerplatformlogo}`,
    tags: ['All', 'LCNC'],
  },
];

export function getAllSkills() {
  return SKILL_DATA;
}

const PROJECT_DATA = [
  {
    id: 'a1',
    title: 'Azure Data Analytics - ETL, Bigdata, ML and BI',
    description:
      'Automated Data Integration, Analytics using Azure Services - ETL, Datawarehousing, Big Data Analytics, Data Modelling, Training (ML), Reporting and UI Dashboard',
    image: `${images.dataanalytics}`,
    tags: ['All', 'Cloud', 'Modernization'],
  },
  {
    id: 'a2',
    title: 'Azure Landing Zone - Enterprize Scale Sample',
    description:
      'Sample Enterprize Scale Azure Landing Zone Architecture with required governance, security for Business Applications',
    image: `${images.azurelandingzone}`,
    tags: ['All', 'Cloud'],
  },
  {
    id: 'a3',
    title: 'Application Modernization - Serverless Pattern',
    description:
      'Serverless Architecture - Sample eCommerce SMB Microservice Design Pattern',
    image: `${images.ecommerceserverless}`,
    tags: ['All', 'Cloud', 'Modernization', 'Full Stack'],
  },
  {
    id: 'a4',
    title: 'Application Modernization - Containers',
    description:
      'Container as a Service Architecture - Sample eCommerce SMB Container Implementation',
    image: `${images.ecommercecontainer}`,
    tags: ['All', 'Cloud', 'Modernization'],
  },
  {
    id: 'a5',
    title: 'Application Modernization - Azure IaaS',
    description:
      'Infrastructure as a Service Architecture - Sample eCommerce SMB Azure IaaS Implementation',
    image: `${images.ecommercevmasg}`,
    tags: ['All', 'Cloud', 'Full Stack'],
  },
  {
    id: 'a6',
    title: 'Full Stack Web Application - NextJS and Azure services',
    description:
      'Full Stack Web Application using NextJS, ReactJS, APIs, NodeJS, OAuth, NoSQL, CMS, ElasticSearch, Redis, Payments, Webhooks',
    image: `${images.ecommercemicroservices}`,
    tags: ['All', 'Cloud', 'Full Stack'],
  },
  {
    id: 'a7',
    title: 'Azure DevOps - Overall Strategy',
    description:
      'Automate Application Life Cycle using PR/CI/CD Azure DevOps Pipelines - with/without containers',
    image: `${images.devOpsOverallStrategy}`,
    tags: ['All', 'Cloud', 'Modernization'],
  },
  {
    id: 'a8',
    title: 'Azure DevOps IaC - Automate Deploy AKS Cluster using Azure ARM Templates and DevOps',
    description:
      'Automate Deploy AKS Cluster using Azure ARM Template(Infra as Code) and DevOps Pipeline',
    image: `${images.devOpsAKSIaC}`,
    tags: ['All', 'Cloud', 'Modernization'],
  },
  {
    id: 'a9',
    title: 'Azure DevOps CICD - Deploy Containers in AKS using Azure ARM Templates(IaC) and DevOps',
    description:
      'Automate Application Build and Deploy in AKS Cluster using Azure ARM Templates(IaC), DevOps, Docker and Kubernetes',
    image: `${images.containersAKSdevOpsIaC}`,
    tags: ['All', 'Cloud', 'Modernization', 'Full Stack'],
  },
  {
    id: 'a10',
    title: 'Azure DevOps CICD - Automate Build and Deploy Application using ARM Template(IaC) and DevOps Pipeline',
    description:
      'Automate Application Build and Deploy in Azure App Service using Azure ARM Template(IaC), DevOps Pipeline',
    image: `${images.webAppdevOpsIaC}`,
    tags: ['All', 'Cloud', 'Modernization', 'Full Stack'],
  },
  {
    id: 'a11',
    title: 'Azure Power Platform - Organization Strategy',
    description:
      'Enterprize Scale Power Platform Architecture Landing Zone with required governance, security for Business Applications',
    image: `${images.powerplatformstrategy}`,
    tags: ['All', 'Power Platform'],
  },
  {
    id: 'a12',
    title: 'Azure Power Platform - Application Integration',
    description:
      'Seamless Integration Power Platform with Consumer Applications to Automate Business Processes, Power Apps, Virtual Agents, Power BI, AI Builder',
    image: `${images.powerplatformintegration}`,
    tags: ['All', 'Cloud', 'Power Platform', 'Full Stack'],
  },
];

export function getAllProjects() {
  return PROJECT_DATA;
}

