import React from 'react';
import { motion } from 'framer-motion';

import { AppWrap } from '../../wrapper';
import { images } from '../../constants';
import './Header.scss';

const scaleVariants = {
  whileInView: {
    scale: [0, 1],
    opacity: [0, 1],
    transition: {
      duration: 1,
      ease: 'easeInOut',
    },
  },
};

const Header = () => (
  <div className="app__header app__flex">
    <motion.div
      whileInView={{ x: [-100, 0], opacity: [0, 1] }}
      transition={{ duration: 0.5 }}
      className="app__header-info"
    >
      <div className="app__header-badge">
        <div style={{ marginLeft: 80 }} className="tag-cmp app__flex">
          <div>
            <p className="p-bold">Cloud Architect</p>
          </div>
          <span>
            <img src={images.cloudarchitect} alt="cloud architect" />
          </span>
        </div>
        <div style={{ marginLeft: 0 }} className="tag-cmp app__flex">
          <div>
            <p className="p-bold">Full Stack and DevOps</p>
          </div>
          <span>
            <img src={images.web} alt="Programmer" />
          </span>
        </div>
      </div>
    </motion.div>

    <motion.div
      whileInView={{ opacity: [0, 1] }}
      transition={{ duration: 0.5, delayChildren: 0.5 }}
      className="app__header-img"
    >
      <img src={images.profile1} alt="profile_bg" />
      {/* <motion.img
        whileInView={{ scale: [0, 1] }}
        transition={{ duration: 1, ease: 'easeInOut' }}
        className="overlay_circle"
      /> */}
    </motion.div>

    <motion.div
      variants={scaleVariants}
      whileInView={scaleVariants.whileInView}
      className="app__header-circles"
    >
      {[images.awscreds, images.azurecreds, images.pegacreds].map(
        (circle, index) => (
          <div className="circle-cmp app__flex" key={`circle-${index}`}>
            <img src={circle} alt="profile_bg" />
          </div>
        ),
      )}
    </motion.div>
  </div>
);

export default AppWrap(Header, 'home');
