import React from 'react';

import { images } from '../../constants';
import { AppWrap, MotionWrap } from '../../wrapper';
import './Footer.scss';

const Footer = () => (
  <>
    <h2 className="head-text">
      Reach <span>me</span>
    </h2>

    <div className="app__footer-cards">
      <div className="app__footer-card ">
        <a href="mailto:dinakarpotu@gmail.com" className="p-text">
          <img src={images.email} alt="email" />
        </a>
        <a href="mailto:dinakarpotu@gmail.com" className="p-text">
          e-mail@dinakar
        </a>
      </div>
      <div className="app__footer-card">
        <a
          href="https://www.linkedin.com/in/dinakar-potu-270a7b17/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={images.linkedIn} alt="linkedIn" />
        </a>
        <a
          href="https://www.linkedin.com/in/dinakar-potu-270a7b17/"
          className="p-text"
          target="_blank"
          rel="noreferrer"
        >
          profile@linkenIn
        </a>
      </div>
    </div>
  </>
);

export default AppWrap(
  MotionWrap(Footer, 'app__footer'),
  'contact',
  'app__whitebg',
);
