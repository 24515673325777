import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

import { AppWrap, MotionWrap } from '../../wrapper';
import { getAllSkills } from '../../content/portfolio-data';
import './Skills.scss';

const Skills = () => {
  const [skills, setSkills] = useState([]);
  const [filterSkills, setFilterSkills] = useState([]);
  const [activeFilter, setActiveFilter] = useState('All');

  useEffect(() => {
    const skillsData = getAllSkills();
    setSkills(skillsData);
    setFilterSkills(skillsData);
  }, []);

  const handleSkillFilter = (item) => {
    setActiveFilter(item);

    setTimeout(() => {
      if (item === 'All') {
        setFilterSkills(skills);
      } else {
        setFilterSkills(skills.filter((skill) => skill.tags.includes(item)));
      }
    }, 500);
  };

  return (
    <>
      <h2 className="head-text">Technology <span>Stack</span></h2>

      <div className="app__work-filter">
        {['Cloud', 'Modernization', 'Full Stack', 'LCNC', 'All'].map(
          (item, index) => (
            <div
              key={index}
              onClick={() => handleSkillFilter(item)}
              className={`app__work-filter-item app__flex p-text ${
                activeFilter === item ? 'item-active' : ''
              }`}
            >
              {item}
            </div>
          ),
        )}
      </div>

      <div className="app__skills-container">
        <motion.div className="app__skills-list">
          {filterSkills.map((skill, index) => (
            <motion.div
              whileInView={{ opacity: [0, 1] }}
              transition={{ duration: 0.5 }}
              className="app__skills-item app__flex"
              key={index}
            >
              <div
                className="app__flex"
                style={{ backgroundColor: skill.bgColor }}
              >
                <img src={skill.icon} alt={skill.name} />
              </div>
              <p className="p-text">{skill.name}</p>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Skills, 'app__skills'),
  'skills',
  'app__whitebg',
);
