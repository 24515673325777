import React from 'react';
import { FaLinkedin } from 'react-icons/fa';
import { AiOutlineMail } from 'react-icons/ai';

const SocialMedia = () => (
  <div className="app__social">
    <div>
      <a
        href="https://www.linkedin.com/in/dinakar-potu-270a7b17/"
        target="_blank"
        rel="noreferrer"
      >
        <FaLinkedin />
      </a>
    </div>
    <div>
      <a href="mailto:dinakarpotu@gmail.com" target="_blank" rel="noreferrer">
        <AiOutlineMail />
      </a>
    </div>
  </div>
);

export default SocialMedia;
