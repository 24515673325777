import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
// import { AiFillEye } from 'react-icons/ai';

import { AppWrap, MotionWrap } from '../../wrapper';
import './Project.scss';
import { getAllProjects } from '../../content/portfolio-data';

const Project = () => {
  const [projects, setProjects] = useState([]);
  const [filterProject, setFilterProject] = useState([]);
  const [activeFilter, setActiveFilter] = useState('All');
  const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 });

  useEffect(() => {
    const data = getAllProjects();
    setProjects(data);
    setFilterProject(
      data.filter((project) => project.tags.includes(activeFilter)),
    );
  }, []);

  const handleProjectFilter = (item) => {
    setActiveFilter(item);
    setAnimateCard([{ y: 100, opacity: 0 }]);

    setTimeout(() => {
      setAnimateCard([{ y: 0, opacity: 1 }]);

      if (item === 'All') {
        setFilterProject(projects);
      } else {
        setFilterProject(
          projects.filter((project) => project.tags.includes(item)),
        );
      }
    }, 500);
  };

  return (
    <>
      <h2 className="head-text">
        Sample Projects<span> Implemented</span>
      </h2>

      <div className="app__project-filter">
        {['Cloud', 'Modernization', 'Full Stack', 'Power Platform', 'All'].map(
          (item, index) => (
            <div
              key={index}
              onClick={() => handleProjectFilter(item)}
              className={`app__project-filter-item app__flex p-text ${
                activeFilter === item ? 'item-active' : ''
              }`}
            >
              {item}
            </div>
          ),
        )}
      </div>

      <motion.div
        animate={animateCard}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className="app__projects"
      >
        {filterProject.map((project, index) => (
          <motion.div
            whileInView={{ opacity: 1 }}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.5, type: 'tween' }}
            className="app__project-item"
            key={project.title + index}
          >
            <h2 style={{ marginTop: 20 }}> </h2>
            <h2 className="bold-text" style={{ margin: 'auto' }}>
              <span>{project.title}</span>
            </h2>
            <h2 style={{ marginTop: 10 }}> </h2>
            <p className="p-text" style={{ margin: 'auto', marginBottom: 10 }}>
              {project.description}
            </p>
            <img src={project.image} alt={project.title} />
          </motion.div>
        ))}
      </motion.div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Project, 'app__project'),
  'projects',
  'app__primarybg',
);
