import email from '../assets/email.png';
import api from '../assets/api.png';
import css from '../assets/css.png';
import git from '../assets/git.png';
import graphql from '../assets/graphql.png';
import html from '../assets/html.png';
import javascript from '../assets/javascript.png';
import node from '../assets/node.png';
import react from '../assets/react.png';
import redux from '../assets/redux.png';
import profile from '../assets/profile.jpg';
import profile1 from '../assets/profile1.jpg';
import powerplatform from '../assets/powerplatform.png';
import powerplatformlogo from '../assets/powerplatformlogo.png';
import nextjs from '../assets/nextjs.png';
import cloud from '../assets/cloud.png';
import k8s from '../assets/k8s.png';
import azurecreds from '../assets/azure-badge.png';
import awscreds from '../assets/aws-badge.png';
import pegacreds from '../assets/pega-badge.png';
import robot from '../assets/robot.png';
import awsazure from '../assets/awsazure.png';
import mernnext from '../assets/mernnext.png';
import modernizeapplications from '../assets/modernizeapplications.png';
import microservices from '../assets/microservices.png';
import docker from '../assets/docker.png';
import devops from '../assets/devops.png';
import biking from '../assets/biking.jpg';
import sheldon from '../assets/sheldon.jpg';
import kinect from '../assets/kinect.png';
import burger from '../assets/burger.jpg';
import horsegram from '../assets/horsegram.jpg';
import javaplum from '../assets/javaplum.jpg';
import mangoes from '../assets/mangoes.jpg';
import nuts from '../assets/nuts.jpg';
import roadtrip from '../assets/roadtrip.jpg';
import badminton from '../assets/badminton.png';
import detectivestories from '../assets/detectivestories.png';
import photography from '../assets/photography.png';
import linkedIn from '../assets/linkedIn.png';
import serverless from '../assets/serverless.png';
import terraformlogo from '../assets/terraformlogo.png';
import cloudarchitect from '../assets/cloudarchitect.png';
import web from '../assets/web.png';
import dataanalytics from '../assets/DataAnalytics.png';
import powerplatformintegration from '../assets/PowerPlatformIntegration.png';
import powerplatformstrategy from '../assets/PowerPlatformStrategy.png';
import azurelandingzone from '../assets/azureLandingZone.png';
import ecommercemicroservices from '../assets/ecommerceMicroservices.png';
import ecommerceserverless from '../assets/ecommerceServerless.png';
import ecommercecontainer from '../assets/ecommerceContainer.png';
import ecommercevmasg from '../assets/ecommerceVMASG.png';
import oauth from '../assets/oauth.png';
import devOpsOverallStrategy from '../assets/devOpsOverallStrategy.png';
import devOpsAKSIaC from '../assets/devOpsAKSIaC.png';
import containersAKSdevOpsIaC from '../assets/containersAKSdevOpsIaC.png';
import webAppdevOpsIaC from '../assets/webAppdevOpsIaC.png';

export default {
  email,
  api,
  css,
  git,
  graphql,
  html,
  javascript,
  node,
  react,
  redux,
  profile,
  profile1,
  powerplatform,
  powerplatformlogo,
  nextjs,
  cloud,
  k8s,
  azurecreds,
  awscreds,
  pegacreds,
  robot,
  awsazure,
  mernnext,
  modernizeapplications,
  microservices,
  docker,
  devops,
  biking,
  sheldon,
  kinect,
  burger,
  horsegram,
  javaplum,
  mangoes,
  nuts,
  roadtrip,
  badminton,
  detectivestories,
  photography,
  linkedIn,
  serverless,
  terraformlogo,
  cloudarchitect,
  web,
  dataanalytics,
  azurelandingzone,
  powerplatformintegration,
  powerplatformstrategy,
  ecommercemicroservices,
  ecommerceserverless,
  ecommercecontainer,
  ecommercevmasg,
  oauth,
  devOpsOverallStrategy,
  devOpsAKSIaC,
  containersAKSdevOpsIaC,
  webAppdevOpsIaC,
};
